import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Select,
    Text,
    Center,
    VStack,
    HStack,
    Spinner,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import { FaUser, FaSave, FaEdit, FaInfo, FaCircle } from 'react-icons/fa';

const serverUrl = process.env.REACT_APP_API_URL;
function LiveMeeting() {
    const [permission, setPermission] = useState(null);
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [recording, setRecording] = useState(false);
    const [paused, setPaused] = useState(false);
    const [timer, setTimer] = useState('00:00:00');
    const [error, setError] = useState('');
    const mediaRecorderRef = useRef(null);
    const timerRef = useRef(null);
    const audioChunksRef = useRef([]);

    useEffect(() => {
        // Request microphone permissions on component mount
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                setPermission(true);
                // Enumerate audio input devices
                return navigator.mediaDevices.enumerateDevices();
            })
            .then(deviceInfos => {
                const audioDevices = deviceInfos.filter(device => device.kind === 'audioinput');
                setDevices(audioDevices);
                if (audioDevices.length > 0) {
                    setSelectedDevice(audioDevices[0].deviceId);
                }
            })
            .catch(err => {
                console.error('Microphone permission denied:', err);
                setPermission(false);
                setError('Microphone permission is required to use this feature. Allow microphone access in your browser, then refresh this page.');
            });
    }, []);

    useEffect(() => {
        let interval = null;
        if (recording && !paused) {
            interval = setInterval(() => {
                setTimer(prev => {
                    const [hours, minutes, seconds] = prev.split(':').map(Number);
                    const totalSeconds = hours * 3600 + minutes * 60 + seconds + 1;
                    const newHours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
                    const newMinutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
                    const newSeconds = String(totalSeconds % 60).padStart(2, '0');
                    return `${newHours}:${newMinutes}:${newSeconds}`;
                });
            }, 1000);
        } else if (!recording || paused) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [recording, paused]);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: { deviceId: selectedDevice } });
            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorderRef.current = mediaRecorder;
            audioChunksRef.current = [];

            mediaRecorder.ondataavailable = event => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
                uploadAudio(audioBlob);
            };

            mediaRecorder.start();
            setRecording(true);
            setPaused(false);
            setTimer('00:00:00');
        } catch (err) {
            console.error('Error starting recording:', err);
            setError('Failed to start recording.');
        }
    };

    const pauseRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.pause();
            setPaused(true);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.resume();
            setPaused(false);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
            setRecording(false);
            setPaused(false);
            setTimer('00:00:00');
        }
    };

    const uploadAudio = async (audioBlob) => {
        try {
            const formData = new FormData();
            formData.append('file', audioBlob, 'recording.mp3');

            const response = await axios.post(`${serverUrl}/api/upload-audio`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Audio uploaded successfully:', response.data);
        } catch (err) {
            console.error('Error uploading audio:', err);
            setError('Failed to upload audio.');
        }
    };

    return (
        <Box className="live-meeting-container" p={4} display="flex" flexDirection="column" justifyContent="center" height="100vh">
            {error && (
                <Alert status="error" mb={4}>
                    <AlertIcon />
                    {error}
                </Alert>
            )}
            <Center>
                <Text fontSize="8xl">{timer}</Text>
            </Center>
            {permission && (
                <VStack spacing={4} mt={8}>
                    <Select
                        value={selectedDevice}
                        onChange={(e) => setSelectedDevice(e.target.value)}
                        width="300px"
                        placeholder="Select Microphone"
                    >
                        {devices.map(device => (
                            <option key={device.deviceId} value={device.deviceId}>
                                {device.label || `Microphone ${device.deviceId}`}
                            </option>
                        ))}
                    </Select>
                    <Text fontSize="lg">
                        {recording ? (paused ? 'Paused' : 'Recording') : 'Not Recording'}
                    </Text>
                    <Center>
                        {!recording && (
                            <Button
                                colorScheme="red"
                                size="lg"
                                borderRadius="full"
                                onClick={startRecording}
                                leftIcon={<FaCircle />}
                            >
                                Record
                            </Button>
                        )}
                        {recording && !paused && (
                            <HStack spacing={4}>
                                <Button bg="#00417D" color="white" onClick={pauseRecording}>
                                    Pause
                                </Button>
                                <Button bg="#00417D" color="white" onClick={stopRecording}>
                                    Stop
                                </Button>
                            </HStack>
                        )}
                        {recording && paused && (
                            <HStack spacing={4}>
                                <Button bg="#00417D" color="white" onClick={resumeRecording}>
                                    Resume
                                </Button>
                                <Button bg="#00417D" color="white" onClick={stopRecording}>
                                    Stop
                                </Button>
                            </HStack>
                        )}
                    </Center>
                </VStack>
            )}
            {permission === null && (
                <Center mt={8}>
                    <Spinner size="xl" />
                    <Text ml={4}>Requesting microphone permissions...</Text>
                </Center>
            )}
        </Box>
    );
}

export default LiveMeeting;